.global-spinner {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 99999;
}

.global-spinner > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
